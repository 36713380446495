import React from "react";
import styled from "styled-components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Text from "./Text";
import { EnergyIcon, LeafSmall, SpeedIcon } from '../../utility/icons';
import styles from "../../utility/styles";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, ${styles.functions.opacityModifier(styles.colors.background, 66)} 0%, transparent 30%), url(${({image}) => image}) center no-repeat;
    background-size: cover;
    flex: 1;
    justify-content: flex-start;
    min-height: 500px;
    padding: 36px 0;
`;

const IconWrapper = styled.div`
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProgressWrapper = styled.div`
    height: 56px;
    width: 56px;
`;

const StatsRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 0;
`;

const StatCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

/**
 * 
 * @param {{image:string, efficiencyString:string, energyString:string, speedString:string, efficiency:number}} props
 */
export default function TeamImage({ image = 'https://via.placeholder.com/518x426.png', efficiencyString, energyString, speedString, efficiency }) {
    const stroke = React.useMemo(() => styles.functions.returnEfficiencyColorFromPercent(efficiency), [efficiency])
    return (
        <Wrapper {...{ image }}>
            <StatsRow>
                <StatCol>
                    <ProgressWrapper>
                        <CircularProgressbarWithChildren
                            value={efficiency}
                            circleRatio={0.67}
                            strokeWidth={8}
                            styles={{
                                root: {},
                                path: {
                                    stroke,
                                    strokeLinecap: 'round',
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    // Rotate the path
                                    transform: 'rotate(0.67turn)',
                                    transformOrigin: 'center center',
                                },
                                trail: {
                                    stroke: styles.colors.white,
                                    strokeWidth: 4,
                                    strokeLinecap: 'round',
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    // Rotate the path
                                    transform: 'rotate(0.67turn)',
                                    transformOrigin: 'center center',
                                }
                            }}
                        >
                            <LeafSmall />
                        </CircularProgressbarWithChildren>
                    </ProgressWrapper>
                    <Text.Heading type='h6' variant='bold'>{efficiencyString}</Text.Heading>
                    <Text.Caption variant='bold' style={{ color: styles.functions.opacityModifier(styles.colors.whiteGray, 80) }}>Avg Efficiency</Text.Caption>
                </StatCol>
                <StatCol>
                    <IconWrapper>
                        <EnergyIcon />
                    </IconWrapper>
                    <Text.Heading type='h6' variant='bold'>{energyString}</Text.Heading>
                    <Text.Caption variant='bold' style={{ color: styles.functions.opacityModifier(styles.colors.whiteGray, 80) }}>Median Energy</Text.Caption>
                </StatCol>
                <StatCol>
                    <IconWrapper>
                        <SpeedIcon />
                    </IconWrapper>
                    <Text.Heading type='h6' variant='bold'>{speedString}</Text.Heading>
                    <Text.Caption variant='bold' style={{ color: styles.functions.opacityModifier(styles.colors.whiteGray, 80) }}>Avg Speed</Text.Caption>
                </StatCol>
            </StatsRow>
        </Wrapper>
    )
}
