import React from 'react';
import styled from 'styled-components';
import Text from '../Text';
import styles from '../../../utility/styles';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled(Text.Body)`
    margin-bottom: 4px;
`;

const EfficiencyText = styled(Text.Caption)`
    margin-bottom: 8px;
`;

const Chart = styled.div`
    width: ${({efficiency}) => `${efficiency * 100}%`};
    background-color: ${({backgroundColor}) => backgroundColor};
    height: 8px;
    position: absolute;
    left: 0;
    bottom: 2px;
`;

const SuperChart = styled.div`
    width: ${({efficiency}) => `${efficiency * 100}%`};
    background-color: ${({backgroundColor}) => backgroundColor};
    height: 12px;
    border-radius: 2px 0 0 2px;
    position: absolute;
    left: 0;
    bottom: 0;
`;

const Indicator = styled.div`
    height: 16px;
    left: ${({efficiency}) => `${efficiency * 100}%`};
    position: absolute;
    width: 8px;
    border: 2px solid ${styles.colors.darkGray};
    border-radius: 2px;
    background-color: ${({backgroundColor}) => backgroundColor};
    bottom: -2px;
    margin-left: -6px;
`;

const StatValue = styled(Text.Body)`
    color: ${styles.colors.white};
`;

const StatTitle = styled(Text.Tiny)`
    color: ${styles.colors.lightGray};
`;

const StatCol = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
`;

const StatWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: 12px;
`;

const BarChart = ({ efficiency }) => {
    const backgroundColor = styles.functions.returnEfficiencyColor(efficiency);

    if (efficiency === null || efficiency === undefined) return (
        <div style={{ position: 'relative', margin: '4px 0' }}>
            <Chart {...{ backgroundColor }} efficiency={1} style={{ borderRadius: '2px' }} />
            <Indicator {...{ backgroundColor }} efficiency={0} />
        </div>
    )

    if (efficiency >= 1) return (
        <div style={{ position: 'relative', margin: '4px 0' }}>
            <Chart backgroundColor={styles.colors.white} efficiency={1} style={{ borderRadius: '2px' }} />
            <SuperChart backgroundColor={styles.colors.white} efficiency={2 - efficiency <= 0 ? 0 : 2 - efficiency} />
            <Chart {...{ backgroundColor }} efficiency={2 - efficiency <= 0 ? 0 : 2 - efficiency} style={{ borderTopLeftRadius: '2px', borderBottomLeftRadius: '2px'}} />
            <Indicator backgroundColor={styles.colors.white} efficiency={2 - efficiency <= 0 ? 0 : 2 - efficiency} />
        </div>
    )

    return (
        <div style={{ position: 'relative', margin: '4px 0' }}>
            <Chart backgroundColor={styles.colors.white} efficiency={1} style={{ borderRadius: '2px' }} />
            <Chart {...{ backgroundColor, efficiency }} style={{ borderTopLeftRadius: '2px', borderBottomLeftRadius: '2px'}} />
            <Indicator backgroundColor={styles.colors.white} {...{ efficiency }} />
        </div>
    )
}

export default function Drive({ data }) {
    const stats = [
        { value: data.distance_traveled_string, title: 'Distance Traveled' },
        { value: data.range_diff_string, title: 'Range Depleted' },
        { value: data.elev_change_string, title: 'Elevation' },
        { value: data.avg_temp_string, title: 'Avg. temp' }
    ]
    const color = styles.functions.returnEfficiencyColor(data.efficiency);
    const efficiencyStr = data.efficiency === null ? '∞' : data.efficiency_string || '-'
    return (
        <Wrapper>
            {data.from_location && data.to_location && <Title variant='bold'>{data.from_location.name} to {data.to_location.name}</Title>}
            <EfficiencyText variant='bold' {...{color}}>{efficiencyStr} Efficiency</EfficiencyText>
            <BarChart efficiency={data.efficiency} />
            <StatWrapper>
                {stats.map(stat => (
                    <StatCol key={stat.title}>
                        <StatValue variant='bold'>{stat.value}</StatValue>
                        <StatTitle>{stat.title}</StatTitle>
                    </StatCol>
                ))}
            </StatWrapper>
        </Wrapper>
    )
}
