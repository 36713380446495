import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';
import TeamImage from '../shared/TeamImage';
import Text from '../shared/Text';
import Button from '../shared/Button';
import ScreenWrapper from '../shared/ScreenWrapper';
import { MobileLogo, PlusIcon } from '../../utility/icons';
import styles from '../../utility/styles';

const StyledRow = styled(Row)`
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
`;

const StyledContainer = styled(Container)`
    margin-top: 48px;
    margin-bottom: 110px;
`

const Avatar = styled(Image)`
    width: 104px;
    height: 104px;
    border-radius: 52px;
    margin-bottom: 8px;
    align-self: flex-end;
    margin-top: calc(-52px + -32px);
    @media only screen and (max-width: 992px) {
        align-self: center;
    }
`;

const AvatarFounder = styled(Image)`
    width: 104px;
    height: 104px;
    border-radius: 52px;
    margin-bottom: 8px;
`;

const InfoCard = styled.div`
    padding: 32px 40px;
    background-color: ${styles.colors.darkGray};
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 992px) {
        align-items: center;
    }
`;

const StyledCaption = styled(Text.Caption)`
    margin-bottom: 24px;
    @media only screen and (max-width: 992px) {
        text-align: center;
    }
`

export default function Team({ stats }) {
    const { andy, schippers, will, perlman, milos } = stats;
    return (
        <ScreenWrapper>
            <StyledContainer>
                <Text.Heading variant='bold' style={{ margin: '24px 0' }}>Our Team</Text.Heading>
                <StyledRow>
                    <Col lg={6} md={12}>
                        <TeamImage
                            efficiency={andy?.efficiency}
                            speedString={andy?.avg_speed}
                            energyString={andy?.avg_energy}
                            efficiencyString={andy?.eff_str}
                            image='https://static-content.tezlabapp.com/web-images/team/AndyH_Vehicle_2x.png'
                        />
                        <InfoCard>
                            <Avatar src='https://static-content.tezlabapp.com/web-images/team/AndyH_Pic_2x.png' />
                            <Text.Heading variant='bold' type='h2'>Andy Hardy</Text.Heading>
                            <Text.Caption variant='bold' style={{ color: styles.colors.lightGray, marginBottom: '8px' }}>Engineering</Text.Caption>
                            <StyledCaption>
                                We think he is a bored chemist that codes. Splits his time between sleeping, biking, skiing and really just making the rest of us feel lazy. Pretty nice guy too.
                            </StyledCaption>
                            <Button style={{ maxWidth: '200px' }} as='a' href={`https://me.tezlab.app/andy-hardy`}>
                                <PlusIcon style={{ marginRight: '8px' }} />
                                Follow on TezLab
                            </Button>
                        </InfoCard>
                    </Col>
                    <Col lg={6} md={12}>
                        <TeamImage
                            efficiency={schippers?.efficiency}
                            speedString={schippers?.avg_speed}
                            energyString={schippers?.avg_energy}
                            efficiencyString={schippers?.eff_str}
                            image='https://static-content.tezlabapp.com/web-images/team/BenS_Vehicle_2x.png'
                        />
                        <InfoCard>
                            <Avatar src='https://static-content.tezlabapp.com/web-images/team/BenS_Pic_2x.png' />
                            <Text.Heading variant='bold' type='h2'>Ben Schippers</Text.Heading>
                            <Text.Caption variant='bold' style={{ color: styles.colors.lightGray, marginBottom: '8px' }}>Founder</Text.Caption>
                            <StyledCaption>
                                Fearless leader that likes to frame all new features as “what if we simply...”. He is also at constant risk of getting canceled for driving a P100D in chill mode.
                            </StyledCaption>
                            <Button style={{ maxWidth: '200px' }} as='a' href={`https://me.tezlab.app/ben-schippers`}>
                                <PlusIcon style={{ marginRight: '8px' }} />
                                Follow on TezLab
                            </Button>
                        </InfoCard>
                    </Col>
                    <Col lg={6} md={12}>
                        <TeamImage
                            efficiency={will?.efficiency}
                            speedString={will?.avg_speed}
                            energyString={will?.avg_energy}
                            efficiencyString={will?.eff_str}
                            image='https://static-content.tezlabapp.com/web-images/team/WillS_Vehicle_2x.png'
                        />
                        <InfoCard>
                            <Avatar src='https://static-content.tezlabapp.com/web-images/team/WillS_Pic_2x.png' />
                            <Text.Heading variant='bold' type='h2'>Will Schenk</Text.Heading>
                            <Text.Caption variant='bold' style={{ color: styles.colors.lightGray, marginBottom: '8px' }}>Founder</Text.Caption>
                            <StyledCaption>
                                Smartest person in the room that figured out how to make this all work. Loves to push the boundries of EV living, and he owns a mill, which when towed cuts efficiency down to 38%.
                            </StyledCaption>
                            <Button style={{ maxWidth: '200px' }} as='a' href={`https://me.tezlab.app/william-schenk`}>
                                <PlusIcon style={{ marginRight: '8px' }} />
                                Follow on TezLab
                            </Button>
                        </InfoCard>
                    </Col>
                    <Col lg={6} md={12}>
                        <TeamImage
                            efficiency={perlman?.efficiency}
                            speedString={perlman?.avg_speed}
                            energyString={perlman?.avg_energy}
                            efficiencyString={perlman?.eff_str}
                            image='https://static-content.tezlabapp.com/web-images/team/BenP_Vehicle_2x.png'
                        />
                        <InfoCard>
                            <Avatar src='https://static-content.tezlabapp.com/web-images/team/BenP_Pic_2x.png' />
                            <Text.Heading variant='bold' type='h2'>Ben Perlman</Text.Heading>
                            <Text.Caption variant='bold' style={{ color: styles.colors.lightGray, marginBottom: '8px' }}>Engineering</Text.Caption>
                            <StyledCaption>
                                He is a big fan of keeping the team in check and reminding us that there's a big userbase outside the US (🇨🇦, km, celcius, other currencies). He's the one that keeps the app moving and systems online, we honestly couldn’t do this without him. #LameCarPic #TwentyOtherEmptyStalls
                            </StyledCaption>
                            <Button style={{ maxWidth: '200px' }} as='a' href={`https://me.tezlab.app/ben-perlman`}>
                                <PlusIcon style={{ marginRight: '8px' }} />
                                Follow on TezLab
                            </Button>
                        </InfoCard>
                    </Col>
                    <Col lg={6} md={12}>
                        <TeamImage
                            efficiency={milos?.efficiency}
                            speedString={milos?.avg_speed}
                            energyString={milos?.avg_energy}
                            efficiencyString={milos?.eff_str}
                            image='https://static-content.tezlabapp.com/web-images/team/MiosR_Vehicle_2x.png'
                        />
                        <InfoCard>
                            <Avatar src='https://static-content.tezlabapp.com/web-images/team/MilosR_Pic_2x.png' />
                            <Text.Heading variant='bold' type='h2'>Milos Roganovic</Text.Heading>
                            <Text.Caption variant='bold' style={{ color: styles.colors.lightGray, marginBottom: '8px' }}>Product Design</Text.Caption>
                            <StyledCaption>
                                When not designing for TezLab and debating new features ideas, his weekends are spent exploring California in a packed Model Y. Secretly pines for a Civic Type R.
                            </StyledCaption>
                            <Button style={{ maxWidth: '200px' }} as='a' href={`https://me.tezlab.app/milos`}>
                                <PlusIcon style={{ marginRight: '8px' }} />
                                Follow on TezLab
                            </Button>
                        </InfoCard>
                    </Col>
                </StyledRow>
                <Text.Heading variant='bold' style={{ margin: '48px 0', textAlign: 'center' }}>Advisory Board</Text.Heading>
                <StyledRow>
                    <Col lg={4} md={6} className='d-flex flex-column align-items-center' style={{ margin: '0 auto 48px auto' }}>
                        <AvatarFounder src='https://static-content.tezlabapp.com/web-images/advisory_board/Jesse_Karp.png' />
                        <Text.Heading type='h2' variant='bold'>Jesse Karp</Text.Heading>
                        <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '12px' }}>Tesla Charging / Supercharger Master</Text.Caption>
                        <Text.Caption style={{ textAlign: 'center', marginBottom: '24px' }}>Jesse has been pivotal in the development and deployment of the Supercharging network.</Text.Caption>
                        <MobileLogo style={{ width: '24px', height: '24px' }} />
                    </Col>
                    <Col lg={4} md={6} className='d-flex flex-column align-items-center' style={{ margin: '0 auto 48px auto' }}>
                        <AvatarFounder src='https://static-content.tezlabapp.com/web-images/advisory_board/John_Stringer.png' />
                        <Text.Heading type='h2' variant='bold'>John Stringer</Text.Heading>
                        <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '12px' }}>Master of X</Text.Caption>
                        <Text.Caption style={{ textAlign: 'center', marginBottom: '24px' }}>Founder of Tesla Owners Silicon Valley and widely respected and loved in the Tesla Community.</Text.Caption>
                        <MobileLogo style={{ width: '24px', height: '24px' }} />
                    </Col>
                    <Col lg={4} md={6} className='d-flex flex-column align-items-center' style={{ margin: '0 auto 48px auto' }}>
                        <AvatarFounder src='https://static-content.tezlabapp.com/web-images/advisory_board/Casey_Neistat.png' />
                        <Text.Heading type='h2' variant='bold'>Casey Neistat</Text.Heading>
                        <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '12px' }}>Athlete / Dad / YouTube</Text.Caption>
                        <Text.Caption style={{ textAlign: 'center', marginBottom: '24px' }}>A remarkable athlete with insatiable appetite for pushing the boundaries in all his pursuits</Text.Caption>
                        <MobileLogo style={{ width: '24px', height: '24px' }} />
                    </Col>
                    <Col lg={4} md={6} className='d-flex flex-column align-items-center' style={{ margin: '0 auto 48px auto' }}>
                        <AvatarFounder src='https://static-content.tezlabapp.com/web-images/advisory_board/Chris_McBob.png' />
                        <Text.Heading type='h2' variant='bold'>Chris McBob</Text.Heading>
                        <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '12px' }}>Helping Solve Full Self Driving</Text.Caption>
                        <Text.Caption style={{ textAlign: 'center', marginBottom: '24px' }}>Taking over YouTube with Full Self Driving Videos.</Text.Caption>
                        <MobileLogo style={{ width: '24px', height: '24px' }} />
                    </Col>
                    <Col lg={4} md={6} className='d-flex flex-column align-items-center' style={{ margin: '0 auto 48px auto' }}>
                        <AvatarFounder src='https://static-content.tezlabapp.com/web-images/advisory_board/David_Hodge.png' />
                        <Text.Heading type='h2' variant='bold'>David Hodge</Text.Heading>
                        <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '12px' }}>CEO @ Standard Fleet / Space Enthusiast</Text.Caption>
                        <Text.Caption style={{ textAlign: 'center', marginBottom: '24px' }}>David is a legendary entrepreneur in the mobility and technology space</Text.Caption>
                        <MobileLogo style={{ width: '24px', height: '24px' }} />
                    </Col>
                </StyledRow>
            </StyledContainer>
        </ScreenWrapper>
    )
}