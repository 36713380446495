import React, { useMemo } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Text from '../shared/Text';
import styled from 'styled-components';
import ScreenWrapper from '../shared/ScreenWrapper';
import StoreButton from '../shared/StoreButton';
import HeroImage from '../shared/HeroImage';
import Button from '../shared/Button';
import InfoCard from '../shared/InfoCard';
import {
    hero_drive,
    home_stats,
    tilted_about,
    tilted_about_small,
    gizmodo,
    tech_crunch,
    unece_logo,
    yahoo,
    DashLogo,
    LargeStatsLogo,
    InsightsLogo,
    CommunityLogo,
    ControlsLogo,
    AutomationLogo,
    AppleIcon,
    AndroidIcon,
    mission_cars
} from '../../utility/icons';
import styles from '../../utility/styles';

const SHOW_BETA = true; // show the same car image for now (Rivian w/ beta sticker)

const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FlexCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and ${styles.breakpoints.tablet} {
        display: ${({ hideOnTablet }) => hideOnTablet ? 'none' : 'flex'};
        justify-content: center;
    }
`;

const MissionRow = styled(Row)`
    display: flex;
    flex-direction: row;
    margin: 32px 48px 100px 48px;
    @media only screen and ${styles.breakpoints.tablet} {
        flex-direction: column-reverse;
    }
`

const MissionCol = styled(Col)`
    display: flex;
    flex-direction: column;
    padding: ${({ textRow }) => textRow ? '48px 24px 0 24px' : '0 24px'};
    @media only screen and ${styles.breakpoints.tablet} {
        align-items: center;
        padding: ${({ textRow }) => textRow ? '24px' : '0'};
        background-color: ${({ textRow }) => textRow ? styles.colors.darkGray : 'transparent'};
    }
`;

const LargeTiltedWrapper = styled(Col)`
    display: flex;
    align-items: center;
    @media only screen and ${styles.breakpoints.mobileL} {
        display: none;
    }
`;

const SmallTilted = styled(Image)`
    display: none;
    @media only screen and ${styles.breakpoints.mobileL} {
        display: flex;
        margin: auto;
        width: 100%;
        height: auto;
        margin-bottom: 24px;
    }
`;

const CarsImage = styled(Image)`
    display: flex;
    margin: auto;
    width: 100%;
`;

const StoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-radius: 1px;
    padding: 32px;
    background: ${styles.colors.darkGray};
`;

const Logo = styled.div`
    margin-bottom: 16px;
`;


export default function Home({ stats, ratings, metric = false, headers }) {
    const src = useMemo(() => SHOW_BETA ? mission_cars[1] : mission_cars[Math.floor(Math.random() * mission_cars.length)], [])
    // set the alignment of the button based on the screen size
    const [alignSelf, setAlignSelf] = React.useState(matchMedia(styles.breakpoints.tablet).matches ? 'center' : 'flex-start');
    // add a listener to the screen size to change the alignment of the button
    React.useEffect(() => {
        const media = matchMedia(styles.breakpoints.tablet);
        const listener = () => setAlignSelf(media.matches ? 'center' : 'flex-start');
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, []);
    return (
        <ScreenWrapper>
            <Container>
                <Text.Huge goBig variant='bold' type='mega' tablet='h1' mobileL='h2' style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '8px', marginTop: '64px' }}>Pioneering the EV lifestyle.</Text.Huge>
                <Text.Body goBig style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '8px' }}>Download today and start exploring the EV lifestyle.</Text.Body>
                <CenteredDiv style={{ marginBottom: '56px' }}>
                    <StoreButton type='apple' />
                    <StoreButton type='android' />
                </CenteredDiv>
            </Container>
            <HeroImage srcs={{ large: hero_drive }} ratios={{ large: 1024 / 449 }} style={{ maxWidth: '1482px' }} />
            <Container fluid style={{ marginBottom: '105px', maxWidth: '1600px' }}>
                <MissionRow>
                    <MissionCol textRow md={5}>
                        <Text.Heading variant='bold' color={styles.colors.whiteGray} style={{ marginBottom: '8px' }} type='mega' tabletP='h3'>Our Mission</Text.Heading>
                        <Text.Heading type='h4' tabletP='body' color={styles.colors.whiteGray} style={{ marginBottom: '8px' }}>“Our mission is to create the ultimate consumer application experience for EV owners, blending innovative design with cutting-edge technology to empower and delight our customers on every journey toward a sustainable future.”</Text.Heading>
                        <Text.Body color={styles.colors.whiteGray} style={{ marginBottom: '24px' }}>- TezLab Team</Text.Body>
                        <Button style={{ alignSelf }} as='a' href='/app'>Explore TezLab</Button>
                    </MissionCol>
                    <MissionCol md={7}>
                        <CarsImage {...{ src }} />
                    </MissionCol>
                </MissionRow>
                <SmallTilted src={tilted_about_small} />
                <Text.Heading variant='bold' type='h2' mobileL='h3' style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '4px' }}>You'll love TezLab, here's why:</Text.Heading>
                <Row className='g-0' xs={1} sm={2} md={3}>
                    <FlexCol>
                        <InfoCard
                            imageElement={<DashLogo />}
                            title='The Dash'
                            body='New to Tesla or a veteran, the EV lifestyle is made easy with a  customizable dashboard.'
                        />
                        <InfoCard
                            imageElement={<InsightsLogo />}
                            title='Real Time Insights'
                            body='We make range easy to understand by providing live real world calculations.'
                        />
                        <InfoCard
                            imageElement={<CommunityLogo />}
                            title='Our Community'
                            body='Create public and private groups and share drives, zaps, and charging locations with friends.'
                        />
                        <InfoCard
                            showOnTablet
                            imageElement={<ControlsLogo />}
                            title='Fast Car Controls'
                            body='Easy access to all controls with customizable quick controls available on your dashboard.'
                        />
                        <InfoCard
                            showOnTablet
                            imageElement={<LargeStatsLogo />}
                            title='Best in Class Stats'
                            body='Review usage reports, charges, charge locations, and drives. Tag your drives to keep them organized.'
                        />
                        <InfoCard
                            showOnTablet
                            imageElement={<AutomationLogo />}
                            title='Automation'
                            body='From location based plugin reminders to scheduling your climate while on the go.'
                        />
                    </FlexCol>
                    <LargeTiltedWrapper>
                        <Image fluid src={tilted_about} />
                    </LargeTiltedWrapper>
                    <FlexCol hideOnTablet>
                        <InfoCard
                            imageElement={<ControlsLogo />}
                            title='Fast Car Controls'
                            body='Easy access to all controls with customizable quick controls available on your dashboard.'
                        />
                        <InfoCard
                            imageElement={<LargeStatsLogo />}
                            title='Best in Class Stats'
                            body='Review usage reports, charges, charge locations, and drives. Tag your drives to keep them organized.'
                        />
                        <InfoCard
                            imageElement={<AutomationLogo />}
                            title='Automation'
                            body='From location based plugin reminders to scheduling your climate while on the go.'
                        />
                    </FlexCol>
                </Row>
                <CenteredDiv style={{ marginBottom: '112px' }}>
                    <Button as='a' href='/app' style={{ marginRight: '16px' }}>Explore TezLab</Button>
                    <Button as='a' href='/app#pricing' variant='secondary'>Plan Options</Button>
                </CenteredDiv>
                {/* <Text.Heading mobileL='h3' variant='bold' type='h2' style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '24px' }}>Our community has some impressive stats...</Text.Heading>
                <StatsRow className='g-0 mb-4 mx-5' style={{ borderRadius: '2px', overflow: 'hidden' }}>
                    <Col className='px-0' >
                        <ImageBackground src={home_stats}>
                            <Text.Heading variant='bold' type='h1' tablet='h2' mobileL='h3'>The verdict is in - EVs can get you there!</Text.Heading>
                        </ImageBackground>
                    </Col>
                    <Col md={5} className='px-0'>
                        <BlueCard>
                            <Text.Heading variant='bold' type='h5' mobileL='body'>Our users are conquering the EV lifestyle many {metric ? "kilometers" : "miles"} at a time.</Text.Heading>
                            <div>
                                <Text.Huge variant='bold' tablet='h1'>{stats.driving_data.distance_traveled}</Text.Huge>
                                <Text.Body>{metric ? "Kilometeres" : "Miles"} Driven</Text.Body>
                            </div>
                            <RowDiv>
                                <ColDiv>
                                    <Text.Heading variant='bold' type='h3'>{stats.charging_data.percent}</Text.Heading>
                                    <Text.Tiny style={{ maxWidth: '120px' }}>Superchargers Visited</Text.Tiny>
                                </ColDiv>
                                <ColDiv>
                                    <Text.Heading variant='bold' type='h3'>{stats.driving_data.avg_distance_traveled}</Text.Heading>
                                    <Text.Tiny style={{ maxWidth: '120px' }}>Avg Drive Distance</Text.Tiny>
                                </ColDiv>
                            </RowDiv>
                        </BlueCard>
                    </Col>
                </StatsRow> */}
                {/* This can be added in when we actually have a stats screen */}
                {/* <Text.Heading variant='regular' type='h5' style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '24px' }}>Explore the community and stats.</Text.Heading> */}
                <CenteredDiv style={{ marginBottom: '112px' }}>
                    {/* <Button as='a' href='/stats'>Explore Stats</Button> */}
                </CenteredDiv>
                <Text.Heading variant='bold' type='h2' mobileL='h3' style={{ textAlign: 'center', color: styles.colors.whiteGray, marginBottom: '24px' }}>Tesla drivers love TezLab</Text.Heading>
                <Row className='mx-5 d-flex justify-content-center' style={{ marginBottom: '24px' }}>
                    <Col md={6} className='px-1 py-1' style={{ maxWidth: '480px' }}>
                        <StoreContainer>
                            <Logo>
                                <AppleIcon />
                            </Logo>
                            <Text.Heading type='h3'>
                                <span style={{ fontWeight: 800 }}>{ratings.apple_rating} </span>
                                out of 5 stars
                            </Text.Heading>
                            <Text.Caption style={{ color: styles.colors.lightGray }}>{ratings.apple_num_reviews} reviews on Apple App Store</Text.Caption>
                            <StoreButton type='apple' />
                        </StoreContainer>
                    </Col>
                    <Col md={6} className='px-1 py-1' style={{ maxWidth: '480px' }}>
                        <StoreContainer>
                            <Logo>
                                <AndroidIcon />
                            </Logo>
                            <div>
                                <Text.Heading type='h3'>
                                    <span style={{ fontWeight: 800 }}>{ratings.google_rating} </span>
                                    out of 5 stars
                                </Text.Heading>
                            </div>
                            <Text.Caption style={{ color: styles.colors.lightGray }}>{ratings.google_num_reviews} reviews on Google Play Store</Text.Caption>
                            <StoreButton type='android' />
                        </StoreContainer>
                    </Col>
                </Row>
                <Text.Heading type='h5' style={{ marginBottom: '24px', color: styles.colors.whiteGray, textAlign: 'center' }}>Featured on...</Text.Heading>
                <Row className='d-flex justify-content-center'>
                    <Col md={6} className='px-0 mx-0 d-flex justify-content-center align-items-center py-2' style={{ flex: 0 }}>
                        <Image src={tech_crunch} height={28} style={{ margin: '0 16px' }} />
                        <Image src={gizmodo} height={17} style={{ margin: '0 16px' }} />
                    </Col>
                    <Col md={6} className='px-0 mx-0 d-flex justify-content-center align-items-center py-2' style={{ flex: 0 }}>
                        <Image src={unece_logo} height={36} style={{ margin: '0 16px' }} />
                        <Image src={yahoo} height={35} style={{ margin: '0 16px' }} />
                    </Col>
                </Row>
            </Container>
        </ScreenWrapper>
    )
}