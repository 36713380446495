import React from 'react';
import styled from 'styled-components';
import Text from '../Text';
import styles from '../../../utility/styles';
import moment from 'moment';

const returnDateStrings = (start, end, timeFormat = 'h:mm A') => {
    const startMoment = moment(start);
    const endMoment = moment(end);
    const startTimeText = startMoment.isSame(endMoment, "day") ? startMoment.format(`${timeFormat}`) : startMoment.format(`dd ${timeFormat}`);
    const endTimeText = startMoment.isSame(endMoment, "day") ? endMoment.format(`${timeFormat}`) : endMoment.format(`dd ${timeFormat}`);
    const deltaVal = startMoment.isSame(endMoment, "day") ? 20 : 30;

    return {
        startTimeText,
        endTimeText,
        deltaVal
    }
}

const Chevron = () => (
    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0 6px' }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.333984 7.33333L0.333984 0.666662L3.66732 4L0.333984 7.33333Z" fill="white" />
    </svg>
)

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const Title = styled(Text.Body)`
    margin-bottom: 8px;
`;

const TimeWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 8px;
    border-radius: 2px;
    background-color: ${styles.colors.mediumGray};
    display: flex;
    margin-bottom: 8px;
`;

const Inactive = styled.div`
    background-color: ${styles.functions.opacityModifier(styles.colors.chargingGreen, 32)};
    width: ${({ start }) => `${start}%`};
    height: 100%;
`;

const Indicator = styled.div`
    width: 8px;
    border: 2px solid #2D3646;
    border-radius: 2px;
    height: 16px;
    background: ${styles.colors.white};
    z-index: 100;
    transform: translate(0px, -4px);
`;

const Active = styled.div`
    background-color: ${styles.colors.chargingGreen};
    width: ${({ width }) => `${width}%`};
    height: 100%;
`;

const StatValue = styled(Text.Body)`
    color: ${styles.colors.white};
`;

const StatTitle = styled(Text.Tiny)`
    color: ${styles.colors.lightGray};
`;

const StatCol = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
`;

export default function Charge({ data }) {
    const { 
        charge_start_percent, 
        charge_start_string, 
        charge_end_percent, 
        charge_end_string, 
        supercharger, 
        user_charge_location,
        start_time_ms,
        stop_time_ms,
        trip_duration_string,
        range_diff_string,
        charge_energy_added_string
    } = data;

    const title = supercharger ? supercharger.title : user_charge_location ? user_charge_location.name : ''
    const { startTimeText, endTimeText } = React.useMemo(() => returnDateStrings(start_time_ms, stop_time_ms), [start_time_ms, stop_time_ms])

    return (
        <Wrapper>
            <Title variant='bold'>{title}</Title>
            <TimeWrapper>
                <Text.Tiny variant='bold'>{startTimeText}</Text.Tiny>
                <Text.Tiny style={{ marginLeft: '6px' }}>({charge_start_string})</Text.Tiny>
                <Chevron />
                <Text.Tiny variant='bold'>{endTimeText}</Text.Tiny>
                <Text.Tiny style={{ marginLeft: '6px' }}>({charge_end_string})</Text.Tiny>
            </TimeWrapper>
            <ChartWrapper>
                <Inactive start={charge_start_percent} />
                <Indicator />
                <Active width={charge_end_percent - charge_start_percent ?? 0} />
                <Indicator />
            </ChartWrapper>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <StatCol>
                    <StatValue variant='bold'>{trip_duration_string}</StatValue>
                    <StatTitle>Duration</StatTitle>
                </StatCol>
                <StatCol>
                    <StatValue variant='bold'>{range_diff_string} ({charge_energy_added_string})</StatValue>
                    <StatTitle>Added</StatTitle>
                </StatCol>
            </div>
        </Wrapper>
    )
}
