import React from 'react'
import styled from 'styled-components';
import styles from '../../../utility/styles';

const Wrapper = styled.div`
    margin: 12px 0;
    border-radius: 4px;
    overflow: hidden;
    background-color: ${styles.colors.background};
    height: 12px;
    display: flex;
`;

const Bar = styled.div`
    height: 100%;
    width: ${({ width }) => `${width}%`};
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const TextWrapper = styled.div`
    background-color: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
`;

const TimeText = styled.div`
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: ${styles.colors.white};
`;

const DistanceText = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: ${styles.colors.white};
`;

export default function Usage({ data }) {
    const { range_lost_by_drive_efficiency: { high, low, medium, phantom }, distance_traveled_string, unplugged, est_max_range } = data;
    const bars = [
        { width: low / est_max_range * 100, backgroundColor: styles.colors.usage.low },
        { width: medium / est_max_range * 100, backgroundColor: styles.colors.usage.medium },
        { width: high / est_max_range * 100, backgroundColor: styles.colors.usage.high },
        { width: phantom / est_max_range * 100, backgroundColor: styles.colors.usage.idle }
    ]

    return (
        <div>
            <Wrapper>
                {bars.map(({ width, backgroundColor }, i) => (
                    <Bar key={`bar-${i + 1}`} {...{ width, backgroundColor }} />
                ))}
            </Wrapper>
            <TextWrapper>
                <TimeText>{unplugged}</TimeText>
                <DistanceText>{distance_traveled_string} Driven</DistanceText>
            </TextWrapper>
        </div>
    )
}
