import chroma from "chroma-js"
import styled, { keyframes } from "styled-components"

const animations = {
	show: (startHeight = '0', startOpacity = '0', endHeight = '100%', endOpacity = '1') => keyframes`
		0% {
			height: ${startHeight};
			opacity: ${startOpacity}
		}
		50% {
			height: ${endHeight};
			opacity: ${startOpacity};
		}
		100% {
			height: ${endHeight};
			opacity: ${endOpacity}
		}
	`,
	hide: (startHeight = '100%', startOpacity = '1', endHeight = '0', endOpacity = '0') => keyframes`
		0% {
			height: ${startHeight};
			opacity: ${startOpacity}
		}
		50% {
			height: ${startHeight};
			opacity: ${endOpacity};
		}
		100% {
			height: ${endHeight};
			opacity: ${endOpacity}
		}
	`,
	rotate: (rotateStart = '0deg', rotateEnd = '360deg') => keyframes`
		from {
			transform: rotate(${rotateStart})
		}
		to {
			transform: rotate(${rotateEnd})
		}
	`
}

const opacity = [
	"00",
	"03",
	"05",
	"08",
	"0A",
	"0D",
	"0F",
	"12",
	"14",
	"17",
	"1A",
	"1C",
	"1F",
	"21",
	"24",
	"26",
	"29",
	"2B",
	"2E",
	"30",
	"33",
	"36",
	"38",
	"3B",
	"3D",
	"40",
	"42",
	"45",
	"47",
	"4A",
	"4D",
	"4F",
	"52",
	"54",
	"57",
	"59",
	"5C",
	"5E",
	"61",
	"63",
	"66",
	"69",
	"6B",
	"6E",
	"70",
	"73",
	"75",
	"78",
	"7A",
	"7D",
	"80",
	"82",
	"85",
	"87",
	"8A",
	"8C",
	"8F",
	"91",
	"94",
	"96",
	"99",
	"9C",
	"9E",
	"A1",
	"A3",
	"A6",
	"A8",
	"AB",
	"AD",
	"B0",
	"B3",
	"B5",
	"B8",
	"BA",
	"BD",
	"BF",
	"C2",
	"C4",
	"C7",
	"C9",
	"CC",
	"CF",
	"D1",
	"D4",
	"D6",
	"D9",
	"DB",
	"DE",
	"E0",
	"E3",
	"E6",
	"E8",
	"EB",
	"ED",
	"F0",
	"F2",
	"F5",
	"F7",
	"FA",
	"FC",
	"FF"
]

const functions = {
	hexToRgb: hex => {
		const r = parseInt(hex.slice(1, 3), 16)
		const g = parseInt(hex.slice(3, 5), 16)
		const b = parseInt(hex.slice(5, 7), 16)
		return `${r}, ${g}, ${b}`
	},
	rgbToHex: rgb => {
		const [r, g, b] = rgb.split(', ')
		return `#${parseInt(r).toString(16)}${parseInt(g).toString(16)}${parseInt(b).toString(16)}`
	},
	/**
	 * 
	 * @param {string} hexColor 
	 * @param {number} percent 
	 * @returns {string}
	 */
	opacityModifier: (hexColor, percent = 100) => {
		return `${hexColor}${opacity[percent]}`
	},
	/**
	 * 
	 * @param {string} startingHex 
	 * @returns 
	 */
	opacityHexToHex: (background = colors.background, startingHex) => {
		try {
			// take the percent and find the opacity value
			const op = startingHex.slice(7)
			// find the index of the opacity value
			const percent = opacity.indexOf(op)
			// convert the background color to rgb
			const bgRGB = functions.hexToRgb(background)
			// convert the starting hex color to rgb
			const startingHexRGB = functions.hexToRgb(startingHex)
			// calculate the new color
			const alpha = percent / 100
			const r = Math.round((1 - alpha) * bgRGB.split(', ')[0] + alpha * startingHexRGB.split(', ')[0])
			const g = Math.round((1 - alpha) * bgRGB.split(', ')[1] + alpha * startingHexRGB.split(', ')[1])
			const b = Math.round((1 - alpha) * bgRGB.split(', ')[2] + alpha * startingHexRGB.split(', ')[2])
			return functions.rgbToHex(`${r}, ${g}, ${b}`)
		} catch (error) {
			return startingHex
		}
	},
	returnEfficiencyColor: efficiency => {
		const f = chroma.scale(colors.efficiencyDomain).domain([0.53, 0.74, 1, 1.60])
		if (efficiency === null) return colors.founders
		if (efficiency === undefined) return colors.usage.idle
		return f(efficiency).hex()
	},
	returnEfficiencyColorFromPercent: percent => {
		const f = chroma.scale(colors.efficiencyDomain).domain([53, 74, 100, 160])
		if (percent === null) return colors.founders
		if (percent === undefined) return colors.usage.idle
		return f(percent).hex()
	},
	returnBatteryColor: bat_pct => {
		if (!bat_pct || bat_pct <= 10) return colors.batteryColorRange[0]
		if (bat_pct > 10 && bat_pct <= 20) return colors.batteryColorRange[1]
		return colors.batteryColorRange[2]
	}
}

const colors = {
	transparent: "transparent",
	darkBackground: '#0F131A',
	background: "#161C28",
	backgroundDeep: "#1d2129",
	backgroundTranslucent: "rgba(36, 41, 51, 0.90)",
	blue: "#10ABFF",
	fadedBlue: "#3C81A6",
	blueWhiteOverlay: "#49BFFF",
	black: "#1C2028",
	green: "#6ce999",
	chargingGreen: "#1BCD6D",
	darkGray: "#2D3646",
	lightBorder: "#f2f2f2",
	mediumGray: "#657185",
	inputBackground: "#1c2028",
	inputBackgroundTranslucent: "rgba(28, 32, 40, 0.9)",
	inputborder: "#41454c",
	inputPlaceholder: "#9a9da3",
	separator: "#e9e9e9",
	neutralGray: "#657b8e",
	purple: "#8e89ff",
	red: "#ff5b5a",
	yellow: "#F2A206",
	mayor: '#FCCE0D',
	white: "#ffffff",
	lightGray: "#9EA6B7",
	whiteGray: "#D8DDE5",
	founders: "#FCCE0D",
	brightYellow: "#FCCE0D",
	efficiencyDomain: ['#FC6883', '#907DFF', '#10ABFF', '#19CFC9'],
	batteryColorRange: ['#FC6883', '#F2A206', '#1BCD6D'],
	regenColorRange: ['#19CFC9', '#1BCD6D'],
	effColorRange: ['#FC6883', '#907DFF', '#10ABFF', '#19CFC9'],
	powerColorRange: ['#4BC674', '#10ABFF', '#FF766C'], // ['green', 'blue', 'red']
	tempColorRange: ['#273782', '#FF6800'],
	usage: {
		low: '#FC6883',
		medium: '#907DFF',
		high: '#10ABFF',
		idle: '#7BA9B8',
		sleeping: '#6678B6',
		offline: '#657185'
	},
	success: '#8BC16A',
	error: '#F67171',
	alert: '#FF9839',
	software_tracker_colors: [
		'#00A3FF',
		'#6BF178',
		'#FF7851',
		'#915EE0',
		'#FFD700',
		'#E84F7D',
		'#69CEBC',
	]
};

const fonts = {
	bold: "WorkSans-Bold",
	default: "WorkSans-Regular",
	light: "WorkSans-Light",
	semi: "WorkSans-SemiBold",
	nunito: "'Nunito Sans', sans-serif"
};

const sizes = {
	mobileS: '320px',
	mobileL: '600px',
	tablet: '768px',
	tabletP: '1024px',
	massive: '1601px'
}

const device = {
	mobileS: `(max-width: ${sizes.mobileS})`,
	mobileL: `(max-width: ${sizes.mobileL})`,
	tablet: `(max-width: ${sizes.tablet})`,
	tabletP: `(max-width: ${sizes.tabletP})`,
	massive: `(min-width: ${sizes.massive})`
}

const styles = {
	header: {
		loggedInText: {
			fontSize: 12,
			fontFamily: fonts.default,
			color: colors.mediumGray
		},
		userNameText: {
			fontSize: 16,
			fontFamily: fonts.bold,
			color: colors.white
		},
	},
	sectionHeaderText: {
		fontSize: 22,
		fontFamily: fonts.bold,
		color: colors.white
	},
	backButtonText: {
		fontSize: 20,
		fontFamily: fonts.bold,
		color: colors.white
	},
	overlayHeaderText: {
		fontSize: 18,
		textTransform: 'uppercase',
		fontFamily: fonts.bold,
		letterSpacing: "0.2ch",
		alignItems: "center"
	},
	subheaderText: {
		fontSize: 15,
		fontFamily: fonts.bold,
	},
	problemText: {
		fontSize: 15,
		fontFamily: fonts.bold,
		color: colors.red
	},
	resolvedText: {
		fontSize: 15,
		fontFamily: fonts.bold,
		color: colors.green
	},
	dataText: {
		fontSize: 13,
		fontFamily: fonts.bold,
		color: colors.mediumGray
	},
	bodyText: {
		fontSize: 19,
		color: colors.white
	},
	bodyTextYellow: {
		fontSize: 19,
		color: colors.yellow
	},
	optionsMenuText: {
		fontSize: 13,
		fontFamily: fonts.regular,
		color: colors.white
	},
	statText: {
		fontSize: 24,
		fontFamily: fonts.bold,
		color: colors.white
	},
	statCaption: {
		fontSize: 14,
		fontFamily: fonts.regular,
		color: colors.mediumGray
	}
}
export default {
	colors,
	fonts,
	styles,
	headerFont: "Futura-CondensedExtraBold",
	breakpoints: device,
	functions,
	animations
};
