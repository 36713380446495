import React from 'react';
import styled from 'styled-components';
import styles from '../../utility/styles';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Avatar from '../shared/shareScreens/Avatar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Post from '../shared/shareScreens/Post';
import Drive from '../shared/shareScreens/Drive';
import Charge from '../shared/shareScreens/Charge';
import Usage from '../shared/shareScreens/Usage';
import ShareScreenWrapper from '../shared/shareScreens/ShareScreenWrapper';
import no_map_fallback from '../../../assets/images/no_map_fallback.png'

const StyledButton = styled(Button)`
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white};
    background-color: ${styles.colors.blue};
    padding: 8px 12px;
    border-radius: 2px;
`;

export default function ShareScreen({ data, ratings }) {

    const driveMedia = React.useMemo(() => {
        if (data && data.activity_detail && data.activity_detail.display_type === 'drive' && !data.media) {
            // this is a drive with no picture
            const url = data && data.og_meta && data.og_meta.find(({property}) => property === 'og:image') ? data.og_meta.find(({property}) => property === 'og:image').content : null
            if (!url) return no_map_fallback
            return [{ url }]
        }
        return null
    }, [data])

    return (
        <ShareScreenWrapper {...{ ratings }}>
            <Container>
                <Row className='d-flex justify-content-center mt-4'>
                    <Col lg={6}>
                        <Avatar.SocialAvatar type={data.display_type} user={data.user} style={{ maxWidth: 500 }} />
                        <Post
                            media={driveMedia ? driveMedia : data.media}
                            body={data.body}
                            location={data.posted_to && data.posted_to.name}
                            timestamp={data.created_at}
                            type={data.display_type}
                            user={data.user}
                        >
                            {data.activity_detail && data.activity_detail.display_type === 'drive' && (
                                <Drive data={data.activity_detail} />
                            )}
                            {data.activity_detail && data.activity_detail.display_type === 'charge' && (
                                <Charge data={data.activity_detail} />
                            )}
                            {data.activity_detail && data.activity_detail.display_type === 'charge_report' && (
                                <Usage data={data.activity_detail} />
                            )}
                        </Post>
                        {data.display_type !== 'zap_post' && (
                            <div style={{ margin: '24px auto', display: 'flex', justifyContent: 'center' }}>
                                <StyledButton href={`https://tezlabapp.com/app/applink/activity/post/${data.id}`} >Open in TezLab</StyledButton>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </ShareScreenWrapper>
    )

}