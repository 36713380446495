import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import styles from '../../../utility/styles';
import Image from 'react-bootstrap/Image';
import { Logo, MobileLogo } from '../../../utility/icons';
import { sliceIt } from '../../../utility/helpers';
import apple_button from '../../../../assets/images/btn_apple_store_2x.png';
import android_button from '../../../../assets/images/btn_google_play_2x.png';
import Share_Site_Footer_2x from '../../../../assets/images/Share_Site_Footer_2x.png';


const Wrapper = styled.div`
    width: '100%';
    background-color: ${styles.colors.background};
`;

const FixedWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${styles.colors.background};
    display: flex;
    justify-content: center;
`;

const FrontContainer = styled.div`
    display: flex;
    background-color: ${styles.colors.background};
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-top: 1px solid ${styles.colors.darkGray};
    flex: 1;
    max-width: 1000px;
    @media (max-width: 760px) {
        justify-content: space-around;
    }
    @media (max-width: 760px) {
        padding: 8px 0;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 12px;
    @media (max-width: 760px) {
        flex: 0;
    }
`;

const StyledLogo = styled(Logo)`
    margin-right: 8px;
    @media (max-width: 760px) {
        display: ${({showSmall}) => showSmall ? 'box' : 'none'};
        margin-bottom: ${({showSmall}) => showSmall ? '4px' : '0'};
    }
`;

const StyledMobileLogo = styled(MobileLogo)`
    transform: scale(0.5);
    @media (min-width: 760px) {
        display: none;
    }
`;

const AwesomeCopy = styled.div`
    font-weight: 600;
    color: ${styles.colors.white};
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 760px) {
        display: none;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 0;
    justify-content: flex-end;
`;

const StoreButton = styled.a`
    width: 118px;
    height: 40px;
    margin: 4px;
`;

const RatingsWrapper = styled.div`
    display: flex;
    margin-top: 32px;
    justify-content: center;
    align-items: flex-start;
    align-self: flex-start;
    @media (max-width: 760px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;

const RatingsCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 760px) {
        display: ${({hideOnMobile}) => hideOnMobile ? 'none' : 'flex'};
        flex-direction: ${({rowOnMobile}) => rowOnMobile ? 'row' : 'column'};
        justify-content: center;
        align-items: center;
    }
`;

const RatingsText = styled.div`
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: ${styles.colors.white};
    @media (max-width: 760px) {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
`;

const RatingsSmallText = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${styles.colors.white};
    @media (max-width: 760px) {
        font-weight: ${({sameOnMobile}) => sameOnMobile ? '400' : '600'};
        font-size: ${({sameOnMobile}) => sameOnMobile ? '13px' : '16px'};
        line-height: ${({sameOnMobile}) => sameOnMobile ? '16px' : '24px'}
    }
`;

const VerticalDivider = styled.div`
    height: 54px;
    width: 0;
    border: 1px solid ${styles.colors.mediumGray};
    margin: 0 16px;
`;

const MobileLogoWrapper = styled.div`
    display: none;
    @media (max-width: 760px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
    }
`;

const StyledCol = styled(Col)`
    @media (max-width: 760px) {
        padding: 0
    }
`;

const Star = ({ fraction, id }) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath {...{ id }}>
                    <rect x='0' y='0' height='24' width={`${24 * fraction}`} />
                </clipPath>
            </defs>
            <path d="M6.59427 21.143C6.33832 21.143 6.08238 21.0619 5.85897 20.8997C5.41239 20.5752 5.2339 20.0262 5.40483 19.501L7.16527 14.0834C7.17947 14.0401 7.16389 13.9927 7.127 13.9658L2.5182 10.6173C2.07161 10.2928 1.89312 9.74382 2.06382 9.21887C2.23453 8.6937 2.7015 8.35458 3.25349 8.35458H8.94999C8.99582 8.35458 9.03592 8.32548 9.05013 8.28194L10.8106 2.86429C10.981 2.33912 11.448 2 12 2C12.552 2 13.019 2.33912 13.1894 2.86429L14.9499 8.28217C14.9641 8.32548 15.0042 8.35481 15.05 8.35481H20.7465C21.2985 8.35481 21.7655 8.69393 21.9362 9.2191C22.1069 9.74428 21.9284 10.2931 21.4818 10.6175L16.8732 13.9658C16.8363 13.9927 16.821 14.0399 16.835 14.0834L18.5954 19.501C18.7661 20.0262 18.5876 20.575 18.141 20.8997C17.6944 21.2241 17.1173 21.2241 16.6707 20.8997L12.0619 17.5513C12.0247 17.5245 11.9753 17.5245 11.9381 17.5513L7.32956 20.8997C7.10615 21.0619 6.85044 21.143 6.59427 21.143Z" fill={styles.colors.darkGray} />
            <path d="M6.59427 21.143C6.33832 21.143 6.08238 21.0619 5.85897 20.8997C5.41239 20.5752 5.2339 20.0262 5.40483 19.501L7.16527 14.0834C7.17947 14.0401 7.16389 13.9927 7.127 13.9658L2.5182 10.6173C2.07161 10.2928 1.89312 9.74382 2.06382 9.21887C2.23453 8.6937 2.7015 8.35458 3.25349 8.35458H8.94999C8.99582 8.35458 9.03592 8.32548 9.05013 8.28194L10.8106 2.86429C10.981 2.33912 11.448 2 12 2C12.552 2 13.019 2.33912 13.1894 2.86429L14.9499 8.28217C14.9641 8.32548 15.0042 8.35481 15.05 8.35481H20.7465C21.2985 8.35481 21.7655 8.69393 21.9362 9.2191C22.1069 9.74428 21.9284 10.2931 21.4818 10.6175L16.8732 13.9658C16.8363 13.9927 16.821 14.0399 16.835 14.0834L18.5954 19.501C18.7661 20.0262 18.5876 20.575 18.141 20.8997C17.6944 21.2241 17.1173 21.2241 16.6707 20.8997L12.0619 17.5513C12.0247 17.5245 11.9753 17.5245 11.9381 17.5513L7.32956 20.8997C7.10615 21.0619 6.85044 21.143 6.59427 21.143Z" clipPath={`url(#${id})`} fill={styles.colors.blue} />
        </svg>
    )
}

/**
 * 
 * @param {{ratings:number}} props
 * @returns 
 */
const Stars = ({ ratings }) => {
    const stars = sliceIt(ratings)
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {stars.map((star, i) => <Star key={`star=${i + 1}`} id={`star=${i + 1}`} fraction={star} />)}
        </div>
    )
}

export default function Footer({ ratings }) {
    return (
        <Wrapper>
            <Container className='d-flex justify-content-center' fluid='lg' style={{marginBottom: 25}}>
                <div style={{maxWidth: 1000}}>
                    <Row>
                        {ratings && <Col className='d-flex' md={6}>
                            <RatingsWrapper>
                                <MobileLogoWrapper>
                                    <StyledLogo showSmall />
                                    <RatingsText style={{width: '65%', textAlign: 'center'}}>Pioneering the Tesla Lifestyle</RatingsText>
                                </MobileLogoWrapper>
                                <RatingsCol rowOnMobile>
                                    <RatingsText>{ratings.app_rating_avg}{' '}</RatingsText>
                                    <RatingsSmallText>out of 5</RatingsSmallText>
                                </RatingsCol>
                                <RatingsCol hideOnMobile>
                                    <VerticalDivider />
                                </RatingsCol>
                                <RatingsCol>
                                    <Stars ratings={ratings.app_rating_avg} />
                                    <RatingsSmallText sameOnMobile>{ratings.app_num_ratings} Reviews</RatingsSmallText>
                                </RatingsCol>
                            </RatingsWrapper>
                        </Col>}
                        <StyledCol md={6}>
                            <Image fluid src={Share_Site_Footer_2x} />
                        </StyledCol>
                    </Row>
                </div>
            </Container>
            <FixedWrapper>
                <FrontContainer>
                    <LogoWrapper>
                        <StyledLogo />
                        <StyledMobileLogo />
                        <AwesomeCopy>Pioneering the Tesla Lifestyle</AwesomeCopy>
                    </LogoWrapper>
                    <ButtonsWrapper>
                        <StoreButton href='https://apps.apple.com/us/app/tezlab/id1239581716' target='_blank' rel="noreferrer">
                            <Image fluid src={apple_button} />
                        </StoreButton>
                        <StoreButton href='https://play.google.com/store/apps/details?id=com.happyfuncorp.tesla' target='_blank' rel="noreferrer">
                            <Image fluid src={android_button} />
                        </StoreButton>
                    </ButtonsWrapper>
                </FrontContainer>
            </FixedWrapper>
        </Wrapper>
    )
}